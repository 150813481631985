import React, { useRef, useState } from "react";
// font awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAddressCard,
  faArrowDown,
  faBarsProgress,
  faDiagramProject,
  faEnvelope,
  faHome,
} from "@fortawesome/free-solid-svg-icons";
import { faLinkedin, faGithub } from "@fortawesome/free-brands-svg-icons";

//custom hooks
import useOnScreen from "../hooks/useOnScreen";

// data
import { codeSnippts } from "../data/codeSnippt";

function Hero() {
  // observer API
  const elementRef = useRef(null);
  const isOnScreen = useOnScreen(elementRef);

  // code snippt
  const [currentIndex, setCurrentIndex] = useState(1);

  const setIndex = (i) => {
    setCurrentIndex(i);
  };

  // codeSnippts.map((c, i) => {
  //   c.properties.map((c) => {
  //     console.log(c);
  //   });
  // });
  return (
    <section ref={elementRef} className={`hero ${isOnScreen ? "in-view" : ""}`}>
      <div className="wrapper p-relative">
        <div className="d-flex flex-column flex-md-row  align-items-center text-center justify-content-center">
          <img
            className="hand-img transition-delay-2 mb-3 mb-md-0 d-block mx-3"
            src="images/hand.svg"
            alt=""
          />
          <h2 className="hero_title mb-3 mb-md-0 transition-delay-1 m-0 fw-bold">
            <span className=" fw-normal"> Hello I’m </span> Michael Garaysi
          </h2>
        </div>
        <h3 className="m-0 mb-3 mb-md-5 fw-bolder  transition-delay-3 text-center hero_dev fw-bold text-uppercase">
          Developer
        </h3>
        <div className="hero_main d-flex text-cente justify-content-center justify-content-md-start text-md-start  position-relative">
          <div>
            <p className="hero_p transition-delay-4">
              I bring ideas to life with code
            </p>
            <ul className="d-flex transition-delay-4 justify-content-center justify-content-md-start list-unstyled hero_links">
              <li>
                <a href="mailto:michaelgaraysi@gmail.com" className="her">
                  <FontAwesomeIcon
                    size="3x"
                    color="grey"
                    className="hero_icon"
                    icon={faEnvelope}
                  />
                </a>
              </li>
              {/*
              <li>
                <a href="/#">
                  <FontAwesomeIcon
                    size="3x"
                    color="grey"
                    className="hero_icon"
                    icon={faGithub}
                  />
                </a>
              </li>
  */}
              <li>
                <a
                  href="https://www.linkedin.com/in/michaelgaraysi"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon
                    size="3x"
                    color="grey"
                    className="hero_icon"
                    icon={faLinkedin}
                  />
                </a>
              </li>
            </ul>
          </div>
          <div className="position-absolute start-50 translate-middle-x hero_micheal">
            <img src="images/micheal.svg" alt="" />
          </div>
          <div className="hero_code-snippt d-none  d-xl-block position-absolute  ">
            <pre>
              class <span>{codeSnippts[currentIndex - 1].class}</span> &#123;
            </pre>
            <pre>&#160; constructor() &#123;</pre>

            {codeSnippts[currentIndex - 1].properties?.map((p) => {
              const [key, value] = Object.entries(p)[0];
              return (
                <pre key={key}>
                  &#160; &#160; {key} = '<span>{value}</span>';
                </pre>
              );
            })}
            <pre>&#160; &#125;</pre>
            <pre>&#125;</pre>

            <div className="code-snippt_buttons position-absolute start-0">
              {codeSnippts.map((s) => {
                return (
                  <button
                    onClick={() => setIndex(s.id)}
                    key={s.id}
                    className={`border-0  ${
                      s.id === currentIndex ? "active" : ""
                    } fw-bold`}
                  >
                    {s.class}
                  </button>
                );
              })}
            </div>
          </div>
        </div>
        <div className="blue position-absolute w-50"></div>
        <div className="purple  position-absolute w-50"></div>

        <h1 className="hero_scroll position-absolute start-50 translate-middle-x">
          <div className="char_wrapper">
            <span className="char1">S</span>
            <span className="char2">c</span>
            <span className="char3">r</span>
            <span className="char4">o</span>
            <span className="char5">l</span>
            <span className="char6">l</span>
            <span className="char7">d</span>
            <span className="char8">o</span>
            <span className="char9">w</span>
            <span className="char10">n</span>
            <span className="char11">t</span>
            <span className="char12">o</span>
            <span className="char13">d</span>
            <span className="char14">i</span>
            <span className="char15">s</span>
            <span className="char16">c</span>
            <span className="char17">o</span>
            <span className="char18">v</span>
            <span className="char19">e</span>
            <span className="char20">r</span>
          </div>

          <a href="#aboutme">
            <FontAwesomeIcon
              className=" position-absolute hero_scroll-icon "
              size="lg"
              color="black"
              icon={faArrowDown}
            />
          </a>
        </h1>
        <nav className="nav-mobile d-block d-md-none position-absolute start-0 w-100">
          <ul className="list-unstyled d-flex justify-content-between">
            <li className="nav-mobile_item active flex-column align-items-center">
              <a
                className=" d-flex flex-column fs-6 fw-bold text-capitalize mx-2  text-decoration-none"
                href="/"
              >
                <FontAwesomeIcon
                  className="mb-2 nav-mobile_icon fa-gradient"
                  size="2x"
                  color="black"
                  icon={faHome}
                />
                Home
              </a>
            </li>
            <li className=" nav-mobile_item  flex-column align-items-center">
              <a
                className=" d-flex flex-column fs-6 fw-bold text-capitalize mx-2  text-decoration-none"
                href="/#aboutme"
              >
                <FontAwesomeIcon
                  className="mb-2 nav-mobile_icon fa-gradient"
                  size="2x"
                  color="black"
                  icon={faAddressCard}
                />
                About Me
              </a>
            </li>
            <li className="d-flex nav-mobile_item  flex-column align-items-center">
              <a
                className=" d-flex flex-column fs-6 fw-bold text-capitalize mx-2  text-decoration-none"
                href="/#experience"
              >
                <FontAwesomeIcon
                  className="mb-2 nav-mobile_icon fa-gradient"
                  size="2x"
                  color="black"
                  icon={faBarsProgress}
                />
                experience
              </a>
            </li>
            <li className="d-flex nav-mobile_item  flex-column align-items-center">
              <a
                className=" d-flex flex-column fs-6 fw-bold text-capitalize mx-2  text-decoration-none"
                href="/#projects"
              >
                <FontAwesomeIcon
                  className="mb-2 nav-mobile_icon  fa-gradient"
                  size="2x"
                  color="black"
                  icon={faDiagramProject}
                />
                Project
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </section>
  );
}

export default Hero;
