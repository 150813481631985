import React from "react";

const Discover = () => {
  return (
    <section className="discover position-relative">
      <div className="wrapper">
        <h1 className="text-center">Kuttheline</h1>
        <h2 className=" text-center fw-bold">
          Discover and Book Professional Appointments
        </h2>
        <div className="discover_challenge">
          <span className="mb-1 d-block">Challenge</span>
          <p className="fw-bold">
            Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet
            sint. Velit officia consequat duis enim velit mollit.
          </p>
        </div>
      </div>
      <div className="purple start-50 top-0  position-absolute w-50"></div>
    </section>
  );
};

export default Discover;
