export const codeSnippts = [
  {
    id: 1,
    class: "Profile",
    properties: [
      { "this.name": "Micheal Garaysi" },
      { "this.traits": "['Web Dev','App Dev']" },
      {
        "this.age": "new Date().getFullYear()-1990",
      },
    ],
  },
  {
    id: 2,
    class: "Hobbies",
    properties: [
      { "this.hobbies": "['Programming','Hiking','Gym']" },
      { "this.sports": "['Baseball','Basketball']" }
    ],
  },
];
