import { useEffect } from "react";

//react router
import { useNavigate } from "react-router-dom";

export const useAccessControl = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const isAllowed = sessionStorage.getItem("isAllowed");

    if (isAllowed !== "true") {
      // navigate("/");
    }
  }, [navigate]);
};
