import React from "react";

// font awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="wrapper">
        <div className="d-flex flex-column flex-md-row  justify-content-between align-items-center">
          <a href="/" className=" mb-4 mb-md-0">
            <img
              className="footer_logo-dark"
              width="140"
              src="images/logo-dark.svg"
              alt=""
            />
          </a>
          <p className=" m-0 opacity-50 mb-4  mb-md-0">
            © 2022 Michael Garaysi. All rights reserved.
          </p>
          <ul className=" d-flex list-unstyled footer_items">
            <li>
              <a
                href="https://www.linkedin.com/in/michaelgaraysi"
                target="_blank"
              >
                <FontAwesomeIcon
                  size="3x"
                  color="white"
                  className="opacity-50 footer_contact"
                  icon={faLinkedin}
                />
              </a>
            </li>
            {/*
            <li>
              <a href="/#">
                <FontAwesomeIcon
                  size="3x"
                  color="white"
                  className="opacity-50 footer_contact"
                  icon={faGithub}
                />
              </a>
            </li>
  */}
            <li>
              <a href="mailto:michaelgaraysi@gmail.com">
                <FontAwesomeIcon
                  size="3x"
                  color="white"
                  className="opacity-50 footer_contact"
                  icon={faEnvelope}
                />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
