export const slides = [
  {
    id: 1,
    image: "https://picsum.photos/800/301/?random",
  },
  {
    id: 2,
    image: "https://picsum.photos/800/302/?random",
  },
  {
    id: 3,
    image: "https://picsum.photos/800/303/?random",
  },
];
