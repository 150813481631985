import React from "react";

// components
import Header from "../components/Header";
import Hero from "../components/Hero";
import CreateProject from "../components/CreateProject";
import CallMe from "../components/CallMe";
import Education from "../components/Education";
import Projects from "../components/Projects";

//react context

const Home = () => {
  return (
    <>
      <Header />
      <Hero />
      <CreateProject />
      <CallMe />
      <Education />
      <Projects />
    </>
  );
};

export default Home;
