import React, { useRef } from "react";

// font awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/fontawesome-free-solid";

// components
import Header from "../components/Header";
import Slider from "../components/Slider";
// import Discover from "../components/Discover";
// import Process from "../components/Process";

// data
import { slides } from "../data/caseStudySlides";

//custom hooks
import useOnScreen from "../hooks/useOnScreen";
import { useAccessControl } from "../hooks/useAccessControl";

const CaseStudy = () => {
  const elementRef = useRef(null);
  const isOnScreen = useOnScreen(elementRef);

  useAccessControl();

  return (
    <>
      <Header />
      <Slider slides={slides} />

      <section className="discover position-relative">
        <div className="wrapper">
          <h2 className=" text-center fw-bold">Procurement</h2>
          <div className="discover_challenge">
            <span className="mb-1 d-block">Challenge</span>
            <p className="fw-bold">
              The whole procurement process was done via email. The user would
              fill out a form and send it to their approving manager. The
              manager would then send the document to the next approving role.
              There were also many conditions that would change who the next
              approving step would be. Not only was this outdated and slow, many
              users did not know who to send the document to next. The progress
              of the request was also unknown to the initial requestor and
              previous roles.
            </p>
          </div>
        </div>
        <div className="purple start-50 top-0  position-absolute w-50"></div>
      </section>

      <section className={`process ${isOnScreen ? "in-view" : ""} `}>
        <div className="wrapper">
          <span className="mb-2 d-block">Process</span>
          <div className="row">
            <div className="process_box transition-delay-1 d-flex gap-3 align-items-center col-12 col-md-6 col-lg-4 ">
              <span>1</span>
              <p className="m-0">
                Meet with stakeholders and clients to discuss problems with
                doing the procurement process through email. Gather information
                on what features and wants they would like the application to
                have.
              </p>
            </div>
            <div className="process_box transition-delay-2 d-flex gap-3 align-items-center col-12 col-md-6 col-lg-4">
              <span>2</span>
              <p className="m-0">
                Create a diagram workflow for procurement using information
                provided. Plan and organize methods on storing data in
                SharePoint.
              </p>
            </div>
            <div className="process_box transition-delay-3 d-flex gap-3 align-items-center col-12 col-md-6 col-lg-4 ">
              <span>3</span>
              <p className="m-0">Design wireframes and mockups.</p>
            </div>
            <div className="process_box transition-delay-4 d-flex gap-3 align-items-center col-12 col-md-6 col-lg-4 ">
              <span>4</span>
              <p className="m-0">
                Create a local environment (using workbench), testing
                environment (for user testing), and production.
              </p>
            </div>
            <div className="process_box transition-delay-5 d-flex gap-3 align-items-center col-12 col-md-6 col-lg-4 ">
              <span>5</span>
              <p className="m-0">
                With the help of Azure Devops create application versioning
                allowing me to deploy different versions of the applications on
                different environments.
              </p>
            </div>
            <div
              ref={elementRef}
              className="process_box d-flex gap-3 transition-delay-6 align-items-center col-12 col-md-6 col-lg-4 "
            >
              <span>6</span>
              <p className="m-0">
                Build application, deploy, and REPEAT! (More and more features
                were requested)
              </p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="wrapper">
          <div className="discover_challenge">
            <span className="mb-1 d-block">Solution</span>
            <p className="fw-bold">
              Created a visual workflow of how the procurement should flow
              through the process depending on price, manager’s approval limit,
              and much more. This fixed the issue of users not knowing who to
              send procurement too. User who submitted procurement request can
              also track progress on request which previously was not possible.
              Depending on what step the procurement request is in, certain
              roles also have the ability to edit the form, which previously was
              not possible. Form would need to be resubmitted and start over
              causing huge delays.
            </p>
            <span className="mb-1 d-block">Features Implemented:</span>
            <ul className="features-wrap">
              <li>
                <FontAwesomeIcon
                  size="1x"
                  color="black"
                  className="mx-2 features_wrap-icon"
                  icon={faCheckCircle}
                />
                Depending on certain conditions, the request can travel through
                11 steps. Throughout this process emails get generated when an
                action is needed from a certain user.
              </li>
              <li>
                <FontAwesomeIcon
                  size="1x"
                  color="black"
                  className="mx-2 features_wrap-icon"
                  icon={faCheckCircle}
                />
                Develop a chat feature allowing users to leave notes and
                comments on a certain request. This allowed all communication
                for requests to be in a central area.{" "}
              </li>
              <li>
                <FontAwesomeIcon
                  size="1x"
                  color="black"
                  className="mx-2 features_wrap-icon"
                  icon={faCheckCircle}
                />
                Ability for certain users to edit requests and depending on what was changed on the request, the request might go back for reapproval also known as “rework”.
              </li>
              <li>
                <FontAwesomeIcon
                  size="1x"
                  color="black"
                  className="mx-2 features_wrap-icon"
                  icon={faCheckCircle}
                />
                Ability to upload multiple files to a request and for certain users to view and download attachments.
              </li>
              <li>
                <FontAwesomeIcon
                  size="1x"
                  color="black"
                  className="mx-2 features_wrap-icon"
                  icon={faCheckCircle}
                />
                History Logger. Every time a request is approved, rejected, canceled, or an item is edited. It saved its history at the bottom of the request.
              </li>
              <li>
                <FontAwesomeIcon
                  size="1x"
                  color="black"
                  className="mx-2 features_wrap-icon"
                  icon={faCheckCircle}
                />
                Generate PDFs using Microsoft Power Automate Tools to send data.
              </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};

export default CaseStudy;
