import React from "react";

// font Awesome icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHtml5 } from "@fortawesome/free-brands-svg-icons";
import { faCss3 } from "@fortawesome/free-brands-svg-icons";
import { faPhp } from "@fortawesome/free-brands-svg-icons";
import { faJs } from "@fortawesome/free-brands-svg-icons";
import { faReact } from "@fortawesome/free-brands-svg-icons";
import { faWordpress } from "@fortawesome/free-brands-svg-icons";
import { faDrupal } from "@fortawesome/free-brands-svg-icons";

const createProject = () => {
  return (
    <section id="aboutme" className="create-project">
      <div className="wrapper d-grid justify-content-center text-center">
        <h2 className="create-project_title mb-3">
          Don't comment <span className="fw-bold">bad code - rewrite it</span>
        </h2>
        <p className="create-project_p">
          My areas of expertise include planning, project management, web
          applications development, team building and stakeholder relations.
          Helped develop, manage, and deploy over 250 custom, responsive,
          mobile-friendly websites in WordPress and Drupal. Developed numerous
          custom CMS themes and plugins to make site building for clients easy.
        </p>
        <div className="d-flex m-auto mb-5">
          <a
            className="text-decoration-none mx-3 view-more_btn rounded "
            href="/#projects"
          >
            View Projects
          </a>
          <a
            className=" text-decoration-none resume_btn rounded"
            href="images/michael-garaysi-resume.pdf"
            target="_blank"
          >
            Download Resume
          </a>
        </div>
        <p className="m-0   fw-semibold  text-uppercase ">Languages:</p>
        <div className="row create-project_skills gap-3  justify-content-start justify-content-md-center ">
          <div className="create-project_skill   col-5  col-md-2  d-flex align-items-center">
            <FontAwesomeIcon
              size="3x"
              color="black"
              className="skill_icon"
              icon={faHtml5}
            />
            <span className="text-uppercase fs-5">CSS/CSS3</span>
          </div>
          <div className="create-project_skill   col-5  col-md-2  d-flex align-items-center">
            <FontAwesomeIcon
              size="3x"
              color="black"
              className="skill_icon"
              icon={faCss3}
            />
            <span className="text-uppercase fs-5">HTML/HTML5</span>
          </div>
          <div className="create-project_skill   col-5  col-md-2  d-flex align-items-center">
            <FontAwesomeIcon
              size="3x"
              color="black"
              className="skill_icon"
              icon={faPhp}
            />
            <span className="text-uppercase fs-5">php</span>
          </div>
          <div className="create-project_skill   col-6  col-md-2 d-flex align-items-center">
            <FontAwesomeIcon
              size="3x"
              color="black"
              className="skill_icon"
              icon={faJs}
            />
            <span className="text-uppercase fs-5">javascript</span>
          </div>
          <div className="create-project_skill   col-5  col-md-2 d-flex align-items-center">
            <FontAwesomeIcon
              size="3x"
              color="black"
              className="skill_icon"
              icon={faReact}
            />
            <span className="text-uppercase fs-5">react</span>
          </div>
        </div>
        <p className=" mb-0   fw-semibold  text-uppercase mt-5 ">Platforms:</p>
        <div className="row create-project_skills  justify-content-start justify-content-md-center ">
          <div className="create-project_skill   col-5  col-md-3 d-flex align-items-center">
            <FontAwesomeIcon
              size="3x"
              color="black"
              className="skill_icon"
              icon={faWordpress}
            />
            <span className="text-capitalize fs-5">wordpress</span>
          </div>
          <div className="create-project_skill   col-5  col-md-3 d-flex align-items-center">
            <FontAwesomeIcon
              size="3x"
              color="black"
              className="skill_icon"
              icon={faDrupal}
            />
            <span className="text-capitalize fs-5">dural</span>
          </div>
          <div className="create-project_skill   col-5  col-md-3 d-flex align-items-center">
            <img src="images/microsoftteam.svg" width="50px" alt="" />
            <span className="text-capitalize fs-5">microsoft team</span>
          </div>

          <div className="create-project_skill   col-5  col-md-3 d-flex align-items-center">
            <img src="images/sharePoint.svg" width="70px" alt="" />
            <span className="text-capitalize fs-5">sharePoint</span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default createProject;
