import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import "./styles.js";

// react
import { useEffect } from "react";

// google analytics
import ReactGA from "react-ga";

// react router
import { createBrowserRouter, RouterProvider } from "react-router-dom";

//components
import Footer from "./assets/components/Footer";

//pages
import Home from "./assets/pages/Home";
import CaseStudy from "./assets/pages/CaseStudy";
import CaseStudyTwo from "./assets/pages/CaseStudyTwo";
import CaseStudyThree from "./assets/pages/CaseStudyThree";

// google analytics

const TRACKING_ID = "UA-248910890-1"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

// react router
const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/procurement-case-study",
    element: <CaseStudy />,
  },
  {
    path: "/search-hearing-case-study",
    element: <CaseStudyTwo />,
  },
  {
    path: "/file-submit-case-study",
    element: <CaseStudyThree />,
  },
]);
function App() {
  // check the most viewd page google analytics
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <main className="main">
      <RouterProvider router={router}></RouterProvider>
      <Footer />
    </main>
  );
}

export default App;
