import React from "react";
import { useEffect } from "react";

// react router
import { useLocation } from "react-router-dom";

//custom hooks
import useLocalStorage from "../hooks/useLocalStorage";

function Header() {
  const { hash } = useLocation();

  useEffect(() => {
    // if not a hash link, scroll to top
    if (!hash) {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      const id = hash.replace("#", "");
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView();
      }
    }
  }, [hash]); // do this on route change

  const [theme, setTheme] = useLocalStorage("theme", "light");

  const enableDarkMode = () => {
    if (theme === "light") {
      setTheme("dark");
      document.body.classList.add("dark-mode");
    } else {
      setTheme("light");
      document.body.classList.remove("dark-mode");
    }
  };

  useEffect(() => {
    if (theme === "dark") {
      document.body.classList.add("dark-mode");
      document.querySelector(".toggle-switch").checked = true;
    }
    return () => {
      document.body.classList.remove("dark-mode");
    };
  }, [theme]);

  return (
    <header className="header">
      <div className="wrapper d-flex justify-content-between align-items-center">
        <a href="/">
          <img
            width={130}
            src={`images/logo-${theme === "dark" ? "dark" : "white"}.svg`}
            alt=""
          />
        </a>

        <nav>
          <ul className="d-none d-md-flex nav_links list-unstyled">
            <li>
              <a className="text-decoration-none nav_link" href="/#aboutme">
                About Me
              </a>
            </li>
            <li>
              <a className="text-decoration-none nav_link" href="/#experience">
                Experience
              </a>
            </li>
            <li>
              <a className="text-decoration-none nav_link" href="/#projects">
                Project
              </a>
            </li>
          </ul>
        </nav>
        <button className="border-0  bg-transparent">
          <label className="switch">
            <input
              className="toggle-switch"
              onClick={enableDarkMode}
              type="checkbox"
            />
            <span className="slider round"></span>
            <img
              className={`position-absolute ${
                theme === "light" ? "opacity-100" : "opacity-0"
              } top-50 translate-middle-y toggle-img`}
              src="images/sun.svg"
              alt=""
            />
            <img
              className={`position-absolute ${
                theme === "light" ? "opacity-0" : "opacity-100"
              } top-50 translate-middle-y toggle-img`}
              src="images/moon.svg"
              alt=""
            />
          </label>
        </button>
      </div>
    </header>
  );
}

export default Header;
