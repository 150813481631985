import React, { useRef } from "react";
import Discover from "../components/Discover";

// font awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/fontawesome-free-solid";

// components
import Header from "../components/Header";
import Slider from "../components/Slider";
import Process from "../components/Process";

// data
import { slides } from "../data/caseStudySlides";

//custom hooks
import useOnScreen from "../hooks/useOnScreen";
import { useAccessControl } from "../hooks/useAccessControl";

const CaseStudyTwo = () => {
  const elementRef = useRef(null);
  const isOnScreen = useOnScreen(elementRef);
  useAccessControl();

  return (
    <>
      <Header />
      {/*
      <Slider slides={slides} />
  */}
      <section className="discover position-relative">
        <div className="wrapper">
          <h2 className=" text-center fw-bold">Search Hearing</h2>
          <p className=" text-center fw-bold">* Gallery has been removed as they contain user sensitive material. </p>
          <div className="discover_challenge">
            <span className="mb-1 d-block">Challenge</span>
            <p className="fw-bold">
              When COVID first hit in March 2020. Most of the court processes were over the phone or in person. Seeing that the courts had to close, phone calls became overwhelming with 2-3 hour wait times. One of the main questions from the public was statuses on their court case. A developer and I were given 3 days to build an application from scratch which allows the public and staff to retrieve information regarding court hearings.

            </p>
          </div>
        </div>
        <div className="purple start-50 top-0  position-absolute w-50"></div>
      </section>

      <section className={`process ${isOnScreen ? "in-view" : ""} `}>
        <div className="wrapper">
          <span className="mb-2 d-block">Process</span>
          <div className="row">
            <div className="process_box transition-delay-1 d-flex gap-3 align-items-center col-12 col-md-6 col-lg-4 ">
              <span>1</span>
              <p className="m-0">
                Meet with clients and stakeholders .
              </p>
            </div>
            <div className="process_box transition-delay-2 d-flex gap-3 align-items-center col-12 col-md-6 col-lg-4">
              <span>2</span>
              <p className="m-0">
                Project planning with backend developer
              </p>
            </div>
            <div className="process_box transition-delay-3 d-flex gap-3 align-items-center col-12 col-md-6 col-lg-4 ">
              <span>3</span>
              <p className="m-0">
                Create user workflow, wireframes, and UI mockups
              </p>
            </div>
            <div
              ref={elementRef}
              className="process_box transition-delay-4 d-flex gap-3 align-items-center col-12 col-md-6 col-lg-4 ">
              <span>4</span>
              <p className="m-0">
                Test and Deploy!
              </p>
            </div>


          </div>
        </div>
      </section>

      <section>
        <div className="wrapper">
          <div className="discover_challenge">
            <span className="mb-1 d-block">Solution</span>
            <p className="fw-bold">
              The application had 3 main search features. If you are an individual trying to find information about your case or a particular case, there is the ability to search by case number or Party Name/Business. If you were a staff member or court official, there was also the ability to search all cases within a certain date range, court site, and/or department code.
            </p>
            <span className="mb-1 d-block">Project Roles:</span>
            <p style={{ marginBottom: 35 }}>
              Lead project manager, UI Designer, Front end developer, server/deployment
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default CaseStudyTwo;
