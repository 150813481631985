import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";

//font awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

//custom hooks
import useOnScreen from "../hooks/useOnScreen";
import useSessionStorage from "../hooks/useSessionStorage";
const Projects = () => {
  // access control
  const [, setIsAllowed] = useSessionStorage("isAllowed", false);

  const elementRef = useRef(null);
  const isOnScreen = useOnScreen(elementRef);

  // verify portoflio
  const [textValue, setTextValue] = useState("");
  const [isValid, setIsValid] = useState(false); // false
  const [isVisible, setIsVisible] = useState(false); //false

  const updatePassword = (e) => {
    const { value } = e.target;
    setTextValue(value);
  };

  const verifyPassword = (e) => {
    const portoflioPw = "m24%kb08";
    const portoflioPw1 = "m24%kb08!";
    e.preventDefault();

    if (textValue === portoflioPw || portoflioPw1) {
      setIsValid(true);
      setIsAllowed(true);
    } else {
      setIsVisible(true);
      setIsValid(false); //false
      setIsAllowed(false); //false
    }
  };
  return (
    <section
      className={`projects ${isOnScreen ? "in-view" : ""}`}
      id="projects"
      ref={elementRef}
    >
      <div className="wrapper">
        <h2 className="text-capitalize mb-3 fw-bold text-center projects_title">
          My Portfolio
        </h2>
        <p
          className={`${isValid ? "d-none" : "opacity-75 mb-2 project-disclaimer-wrap"
            }`}
        >
          Due to the nature of my current employment and to protect the privacy
          of users, a password is needed to view my portoflio. Enter provided
          password below or request password.
        </p>
        <div className="project-wrap">
          <form
            className={`${isValid ? "d-none" : "d-block"
              } d-flex align-items-center flex-column justify-content-center`}
          >
            <div className=" d-flex flex-column position-relative">
              <label className="m-2 text-center mb-1" htmlFor="pass">
                Please Enter The Password
              </label>
              <input
                onChange={updatePassword}
                className="fs-5 mb-1"
                id="pass"
                type="password"
                required
                autoComplete="on"
                value={textValue}
              />
              <span
                className={`mb-1 ${isVisible ? "opacity-100" : "opacity-0"
                  } bottom-0 text-danger fw-bold`}
              >
                Please Enter The Right Password
              </span>
            </div>
            <div className=" d-flex align-items-center position-relative">
              <button
                onClick={verifyPassword}
                className=" px-3 py-1 rounded border-0 d-block mx-3 bg-black text-white fw-bold "
              >
                Send
              </button>
              <a href="mailto:test.gmail.com" className=" text-black">
                Request Password?
              </a>
            </div>
          </form>
          <div className={`${isValid ? "d-block" : "d-none"}`}>
            <div className="project-wrap-header text-center">
              <h2 className="fs-4">React & Microsoft Teams Applications</h2>
              <p className="project-disclaimer-wrap">
                Due to the nature of these applications containing sensitive
                information. Gallery, images, and some information within case studies have been
                removed. However, information can be provided if requested.
              </p>
            </div>
            <div className="row justify-content-center gap-2">
              <div className="col-11 col-md-3 transition-delay-1 projects_box position-relative p-0 rounded">
                <div>
                  <img
                    className=" rounded d-block w-100 h-100"
                    src="images/project-1.svg"
                    alt=""
                  />
                </div>
                <div className="projects_text w-75 top-50 translate-middle text-center start-50 position-absolute">
                  <p className=" text-white fw-bold">
                    Procurement
                    <br />
                    React Application
                  </p>
                  <Link
                    to="/procurement-case-study"
                    className="text-white  justify-content-evenly w-75  m-auto d-flex align-items-center py-2 fs-6 fw-bold px-3 rounded-pill text-capitalize text-decoration-none"
                  >
                    Case Study
                    <FontAwesomeIcon
                      size="lg"
                      color="white"
                      className=""
                      icon={faArrowRight}
                    />
                  </Link>
                </div>
                <div className="overlay position-absolute top-0 start-0 rounded w-100 h-100"></div>
              </div>
              <div className="col-11 col-md-3 transition-delay-2 projects_box position-relative p-0 rounded">
                <div>
                  <img
                    className=" rounded d-block w-100 h-100"
                    src="images/project-1.svg"
                    alt=""
                  />
                </div>
                <div className="projects_text w-75 top-50 translate-middle text-center start-50 position-absolute">
                  <p className=" text-white fw-bold">
                    Search Hearings
                    <br />
                    React Application
                  </p>

                  <Link
                    to="/search-hearing-case-study"
                    className="text-white  justify-content-evenly w-75  m-auto d-flex align-items-center py-2 fs-6 fw-bold px-3 rounded-pill text-capitalize text-decoration-none"
                  >
                    Case Study
                    <FontAwesomeIcon
                      size="lg"
                      color="white"
                      className=""
                      icon={faArrowRight}
                    />
                  </Link>
                </div>
                <div className="overlay position-absolute top-0 start-0 rounded w-100 h-100"></div>
              </div>
              <div className="col-11 col-md-3 transition-delay-3 projects_box position-relative p-0 rounded">
                <div>
                  <img
                    className=" rounded d-block w-100 h-100"
                    src="images/project-1.svg"
                    alt=""
                  />
                </div>
                <div className="projects_text w-75 top-50 translate-middle text-center start-50 position-absolute">
                  <p className=" text-white fw-bold">
                    File Submit
                    <br />
                    React Application
                    <br />
                    Coming soon
                  </p>
                  Case Study

                </div>
                <div className="overlay position-absolute top-0 start-0 rounded w-100 h-100"></div>
              </div>
              <div className="col-11 col-md-3 transition-delay-4 projects_box position-relative p-0 rounded">
                <div>
                  <img
                    className=" rounded d-block w-100 h-100"
                    src="images/project-1.svg"
                    alt=""
                  />
                </div>
                <div className="projects_text w-75 top-50 translate-middle text-center start-50 position-absolute">
                  <p className=" text-white fw-bold">
                    Status Board <br />
                    Microsoft Teams App
                    <br />
                    Coming soon
                  </p>
                </div>
                <div className="overlay position-absolute top-0 start-0 rounded w-100 h-100"></div>
              </div>
            </div>
          </div>
        </div>
        <div className={`${isValid ? "d-block" : "d-none"} project-wrap`}>
          <div className="project-wrap-header text-center">
            <h2 className="fs-4">Custom WordPress & Drupal Websites</h2>
            <p className="project-disclaimer-wrap">
              Please Note: Some of these sites have been updated since last
              developed. Additionally, some projects listed below may have done
              by you while working at an agency or other company. Please reach
              out for more information.
            </p>
          </div>
          <div className="row justify mb-2  justify-content-center gap-2">
            <div className="col-11 col-md-3 transition-delay-6 projects_box position-relative p-0 rounded">
              <div>
                <img
                  className=" rounded d-block w-100 h-100"
                  src="images/project-3.svg"
                  alt=""
                />
              </div>
              <div className="projects_text w-75 top-50 translate-middle text-center start-50 position-absolute">
                <p className=" text-white fw-bold">
                  San Diego Metropolitan Transit System: MTS
                  <br />
                  Drupal
                </p>
                <a
                  className="text-white  justify-content-evenly w-75  m-auto d-flex align-items-center py-2 fs-6 fw-bold px-2 rounded-pill text-capitalize text-decoration-none"
                  href="https://www.sdmts.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  visit website
                  <FontAwesomeIcon
                    size="lg"
                    color="white"
                    className=""
                    icon={faArrowRight}
                  />
                </a>
              </div>
              <div className="overlay position-absolute top-0 start-0 rounded w-100 h-100"></div>
            </div>
            <div className="col-11 col-md-3 transition-delay-5 projects_box position-relative p-0 rounded">
              <div>
                <img
                  className=" rounded d-block w-100 h-100"
                  src="images/project-9.svg"
                  alt=""
                />
              </div>
              <div className="projects_text w-75 top-50 translate-middle text-center start-50 position-absolute">
                <p className=" text-white fw-bold">
                  Fiesta Village
                  <br />
                  Wordpress
                </p>
                <a
                  className="text-white  justify-content-evenly w-75  m-auto d-flex align-items-center py-2 fs-6 fw-bold px-3 rounded-pill text-capitalize text-decoration-none"
                  href="https://www.fiestavillage.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  visit website
                  <FontAwesomeIcon
                    size="lg"
                    color="white"
                    className=""
                    icon={faArrowRight}
                  />
                </a>
              </div>
              <div className="overlay position-absolute top-0 start-0 rounded w-100 h-100"></div>
            </div>
            <div className="col-11 col-md-3 transition-delay-6 projects_box position-relative p-0 rounded">
              <div>
                <img
                  className=" rounded d-block w-100 h-100"
                  src="images/project-2.svg"
                  alt=""
                />
              </div>
              <div className="projects_text w-75 top-50 translate-middle text-center start-50 position-absolute">
                <p className=" text-white fw-bold">
                  Tochta
                  <br />
                  Wordpress
                </p>
                <a
                  className="text-white  justify-content-evenly w-75  m-auto d-flex align-items-center py-2 fs-6 fw-bold px-2 rounded-pill text-capitalize text-decoration-none"
                  href="https://www.tochta.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  visit website
                  <FontAwesomeIcon
                    size="lg"
                    color="white"
                    className=""
                    icon={faArrowRight}
                  />
                </a>
              </div>
              <div className="overlay position-absolute top-0 start-0 rounded w-100 h-100"></div>
            </div>
            <div className="col-11 col-md-3 transition-delay-6 projects_box position-relative p-0 rounded">
              <div>
                <img
                  className=" rounded d-block w-100 h-100"
                  src="images/project-4.svg"
                  alt=""
                />
              </div>
              <div className="projects_text w-75 top-50 translate-middle text-center start-50 position-absolute">
                <p className=" text-white fw-bold">
                  44 Professional Gloves
                  <br />
                  Drupal
                </p>
                <a
                  className="text-white  justify-content-evenly w-75  m-auto d-flex align-items-center py-2 fs-6 fw-bold px-2 rounded-pill text-capitalize text-decoration-none"
                  href="https://44progloves.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  visit website
                  <FontAwesomeIcon
                    size="lg"
                    color="white"
                    className=""
                    icon={faArrowRight}
                  />
                </a>
              </div>
              <div className="overlay position-absolute top-0 start-0 rounded w-100 h-100"></div>
            </div>
          </div>

          <div className="row justify-content-center gap-2">
            <div className="col-11 col-md-3 transition-delay-5 projects_box position-relative p-0 rounded">
              <div>
                <img
                  className=" rounded d-block w-100 h-100"
                  src="images/project-5.svg"
                  alt=""
                />
              </div>
              <div className="projects_text w-75 top-50 translate-middle text-center start-50 position-absolute">
                <p className=" text-white fw-bold">
                  San Diego County Water Authority
                  <br />
                  WordPress
                </p>
                <a
                  className="text-white  justify-content-evenly w-75  m-auto d-flex align-items-center py-2 fs-6 fw-bold px-3 rounded-pill text-capitalize text-decoration-none"
                  href="http://carlsbaddesal.sdcwa.org"
                  target="_blank"
                  rel="noreferrer"
                >
                  visit website
                  <FontAwesomeIcon
                    size="lg"
                    color="white"
                    className=""
                    icon={faArrowRight}
                  />
                </a>
              </div>
              <div className="overlay position-absolute top-0 start-0 rounded w-100 h-100"></div>
            </div>
            <div className="col-11 col-md-3 transition-delay-6 projects_box position-relative p-0 rounded">
              <div>
                <img
                  className=" rounded d-block w-100 h-100"
                  src="images/project-6.svg"
                  alt=""
                />
              </div>
              <div className="projects_text w-75 top-50 translate-middle text-center start-50 position-absolute">
                <p className=" text-white fw-bold">FieldTurf Commercial</p>
                <a
                  className="text-white  justify-content-evenly w-75  m-auto d-flex align-items-center py-2 fs-6 fw-bold px-2 rounded-pill text-capitalize text-decoration-none"
                  href="https://fieldturfcommercial.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  visit website
                  <FontAwesomeIcon
                    size="lg"
                    color="white"
                    className=""
                    icon={faArrowRight}
                  />
                </a>
              </div>
              <div className="overlay position-absolute top-0 start-0 rounded w-100 h-100"></div>
            </div>
            <div className="col-11 col-md-3 transition-delay-6 projects_box position-relative p-0 rounded">
              <div>
                <img
                  className=" rounded d-block w-100 h-100"
                  src="images/project-7.svg"
                  alt=""
                />
              </div>
              <div className="projects_text w-75 top-50 translate-middle text-center start-50 position-absolute">
                <p className=" text-white fw-bold">
                  Integrated Dental
                  <br />
                  WordPress
                </p>
                <a
                  className="text-white  justify-content-evenly w-75  m-auto d-flex align-items-center py-2 fs-6 fw-bold px-2 rounded-pill text-capitalize text-decoration-none"
                  href="https://integrateddental.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  visit website
                  <FontAwesomeIcon
                    size="lg"
                    color="white"
                    className=""
                    icon={faArrowRight}
                  />
                </a>
              </div>
              <div className="overlay position-absolute top-0 start-0 rounded w-100 h-100"></div>
            </div>
            <div className="col-11 col-md-3 transition-delay-6 projects_box position-relative p-0 rounded">
              <div>
                <img
                  className=" rounded d-block w-100 h-100"
                  src="images/project-8.svg"
                  alt=""
                />
              </div>
              <div className="projects_text w-75 top-50 translate-middle text-center start-50 position-absolute">
                <p className=" text-white fw-bold">
                  The Broken Yolk Cafe
                  <br />
                  WordPress
                </p>
                <a
                  className="text-white  justify-content-evenly w-75  m-auto d-flex align-items-center py-2 fs-6 fw-bold px-2 rounded-pill text-capitalize text-decoration-none"
                  href="https://www.thebrokenyolkcafe.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  visit website
                  <FontAwesomeIcon
                    size="lg"
                    color="white"
                    className=""
                    icon={faArrowRight}
                  />
                </a>
              </div>
              <div className="overlay position-absolute top-0 start-0 rounded w-100 h-100"></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Projects;
