import React from "react";

const callMe = () => {
  return (
    <section className="callme">
      <div className="wrapper">
        <div className="callme_box d-flex  text-center text-md-start flex-column flex-lg-row  align-items-center roudned-1">
          <div className="d-flex flex-column flex-md-row align-items-center callme_title-wrapper">
            <img src="images/call-me.svg" alt="" />
            <h1 className="callme_title fw-bold">Create A Project</h1>
          </div>
          <p className="callme_p m-0 opacity-75">
            Interested in working together? We should queue up a time to chat.
            I’ll buy the coffee.
          </p>
          <a
            className=" text-decoration-none d-block rounded fw-bold"
            href="mailto:michaelgaraysi@gmail.com?subject=Create a Project Contact"
          >
            Let's do it now
          </a>
        </div>
      </div>
    </section>
  );
};

export default callMe;
