import React from "react";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

// import required modules
import { EffectCoverflow, Pagination } from "swiper";
const Slider = ({ slides }) => {
  return (
    <section className="mb-5 position-relative">
      <Swiper
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={"auto"}
        loop={true}
        pagination={{
          clickable: true,
        }}
        coverflowEffect={{
          rotate: 50,
          stretch: 10,
          depth: 30,
          modifier: 1,
          slideShadows: true,
        }}
        modules={[EffectCoverflow, Pagination]}
        className="mySwiper"
      >
        {slides.map((slide) => {
          return (
            <SwiperSlide key={slide.id}>
              <div className=" position-relative">
                <img src={slide.image} alt="" />
              </div>
              ,
            </SwiperSlide>
          );
        })}
      </Swiper>
      <div className="blue top-50 start-0  position-absolute w-50"></div>
    </section>
  );
};

export default Slider;
