import React, { useRef } from "react";

//custom hooks
import useOnScreen from "../hooks/useOnScreen";

const Education = () => {
  const elementRef = useRef(null);
  const isOnScreen = useOnScreen(elementRef);

  return (
    <section
      className={`education ${isOnScreen ? "in-view" : ""} `}
      id="experience"
    >
      <div className="wrapper">
        <h2 className="education_title fw-bold text-center text-capitalize">
          <span ref={elementRef}>This is my </span>
          career<br /> <span>and </span>
          Education Journey
        </h2>
        <div className="education_experience d-grid position-relative ">
          <p className=" vertical-text top-md top-50 translate-middle-y text-uppercase position-absolute fw-bold">
            experience
          </p>
          <div className="gradient-bar position-absolute"></div>
          <div className="purple position-absolute opacity-25"></div>
          <div className="blue position-absolute opacity-25"></div>
          <div className="experience_box d-flex align-items-center">
            <div className=" rounded-circle">
              <img src="images/edu-1.svg" alt="" />
            </div>
            <div>
              <h2>Web Developer</h2>
              <p className="opacity-75  mb-1">
                Superior Court of San Bernardino County
              </p>
              <p className=" opacity-75 mb-1">Jul 2019 - Current</p>
              <p className=" opacity-75">
                Primary developer at SB Superior Court. Maintain, customize, and update court’s public and internal sites. Develop internal web applications on SharePoint and Microsoft Teams using React. Automate workflows online.
              </p>
            </div>
          </div>
          <div className="experience_box d-flex align-items-center">
            <div className=" rounded-circle">
              <img src="images/edu-2.svg" alt="" />
            </div>
            <div>
              <h2>Web Team Director</h2>
              <p className="opacity-75  mb-1">Lemonade Stand</p>
              <p className=" opacity-75 mb-1">Jun 2017 - Jul 2019</p>
              <p className=" opacity-75">
                Manage and lead the web team. Lead web developer. Create and
                optimize internal process, and handle client relations. Develop
                custom, responsive, mobile-first Wordpress themes, with company
                branding. Work with clients and team to create a final product.
              </p>
            </div>
          </div>
          <div className="experience_box d-flex align-items-center">
            <div className=" rounded-circle">
              <img src="images/edu-3.svg" alt="" />
            </div>
            <div>
              <h2>Web Developer</h2>
              <p className="opacity-75  mb-1">Gaslamp Media</p>
              <p className=" opacity-75 mb-1">Jul 2019 - Current</p>
              <p className=" opacity-75">
                Developed custom, responsive, mobile-first Wordpress and Drupal
                websites via PSD designs. Write and maintain custom Wordpress
                plugins.
              </p>
            </div>
          </div>
        </div>
        <div className="education_experience justify-content-center education_history  d-grid position-relative ">
          <div className="experience_box d-flex justify-content-end flex-row-reverse align-items-center">
            <p className="vertical-text   text-uppercase position-absolute fw-bold">
              Education
            </p>
            <div className=" rounded-circle position-relative">
              <img src="images/edu-4.svg" alt="" />
              <div className="gradient-bar position-absolute"></div>
            </div>
            <div>
              <p className=" opacity-75 mb-1">2012 - 2015</p>
              <h2 className="fs-4">
                California State University - San Bernardino
              </h2>
              <p className=" opacity-75">
                Bachelor of Arts - BA, Computer System - Web Programing
              </p>
            </div>
          </div>
          <div className="experience_box d-flex   justify-content-end flex-row-reverse align-items-center">
            <div className=" rounded-circle">
              <img src="images/edu-5.svg" alt="" />
            </div>
            <div>
              <p className=" opacity-75 mb-1">2010 - 2012</p>
              <h2 className=" fs-4">Riverside City College</h2>
              <p className="opacity-75  mb-1">
                Associate of Science - AS, Mathematics and Computer Science
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Education;
