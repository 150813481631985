import React, { useRef } from "react";

//custom hooks
import useOnScreen from "../hooks/useOnScreen";

const Process = () => {
  const elementRef = useRef(null);
  const isOnScreen = useOnScreen(elementRef);

  console.log(isOnScreen);
  return (
    <section className={`process ${isOnScreen ? "in-view" : ""} `}>
      <div className="wrapper">
        <span className="mb-2 d-block">Process</span>
        <div className="row">
          <div className="process_box transition-delay-1 d-flex gap-3 align-items-center col-12 col-md-6 col-lg-4 ">
            <span>1</span>
            <p className="m-0">
              Amet minim mollit non deserunt ullamco est sit aliqua dolor do
              amet sint.
            </p>
          </div>
          <div className="process_box transition-delay-2 d-flex gap-3 align-items-center col-12 col-md-6 col-lg-4">
            <span>2</span>
            <p className="m-0">
              Amet minim mollit non deserunt ullamco est sit aliqua dolor do
              amet sint.
            </p>
          </div>
          <div className="process_box transition-delay-3 d-flex gap-3 align-items-center col-12 col-md-6 col-lg-4 ">
            <span>3</span>
            <p className="m-0">
              Amet minim mollit non deserunt ullamco est sit aliqua dolor do
              amet sint.
            </p>
          </div>
          <div className="process_box transition-delay-4 d-flex gap-3 align-items-center col-12 col-md-6 col-lg-4 ">
            <span>4</span>
            <p className="m-0">
              Amet minim mollit non deserunt ullamco est sit aliqua dolor do
              amet sint.
            </p>
          </div>
          <div className="process_box transition-delay-5 d-flex gap-3 align-items-center col-12 col-md-6 col-lg-4 ">
            <span>5</span>
            <p className="m-0">
              Amet minim mollit non deserunt ullamco est sit aliqua dolor do
              amet sint.
            </p>
          </div>
          <div
            ref={elementRef}
            className="process_box d-flex gap-3 transition-delay-6 align-items-center col-12 col-md-6 col-lg-4 "
          >
            <span>6</span>
            <p className="m-0">
              Amet minim mollit non deserunt ullamco est sit aliqua dolor do
              amet sint.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Process;
