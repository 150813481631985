import React from "react";
import Discover from "../components/Discover";

// components
import Header from "../components/Header";
import Slider from "../components/Slider";
import Process from "../components/Process";

// data
import { slides } from "../data/caseStudySlides";

//custom hooks
import { useAccessControl } from "../hooks/useAccessControl";

const CaseStudyThree = () => {
  useAccessControl();
  return (
    <>
      <Header />
      <Slider slides={slides} />
      <Discover />
      <Process />
    </>
  );
};

export default CaseStudyThree;
